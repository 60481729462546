<template>
    <div>
        <div class="top-header blackBG border-bottom">
            <div class="marquee border-top-0 border-bottom">
                <bannerslider/>
            </div>
        </div>
        <section class="blockElement ideaMenu secondaryBG border-bottom multiMenu">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                       <ul class="economyList d-flex align-items-center justify-content-center mb-0">
                           <li><a @click.prevent="countryTabs = 'Europe'" :class="(countryTabs == 'Europe')?'active':''" href="javascript:void(0)">Europe</a></li>
                           <li><a @click.prevent="countryTabs = 'America'" :class="(countryTabs == 'America')?'active':''" href="javascript:void(0)">America</a></li>
                           <li><a @click.prevent="countryTabs = 'Asia'" :class="(countryTabs == 'Asia')?'active':''" href="javascript:void(0)">Asia</a></li>
                           <li><a @click.prevent="countryTabs = 'Africa'" :class="(countryTabs == 'Africa')?'active':''" href="javascript:void(0)">Africa</a></li>
                           <li><a @click.prevent="countryTabs = 'Australia'" :class="(countryTabs == 'Australia')?'active':''" href="javascript:void(0)">Australia</a></li>
                           <li><a @click.prevent="countryTabs = 'G20'" :class="(countryTabs == 'G20')?'active':''" href="javascript:void(0)">G20</a></li>
                       </ul>
                    </div>
                </div>
            </div>
        </section>
        <section class="blockElement bg-white space gdpCompare">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-xl-8 mb-4 mb-xl-0">
                        <div class="breadCrum d-flex align-items-center justify-content-between flex-wrap mb-md-4">
                            <div class="bdPage">
                                <div class="d-flex align-items-center mb-2">
                                    <h2 class="mb-0 me-2">GDP Growth Rate India vs </h2>
                                    <div class="position-relative">
                                        <a @click="counttryTabs =!counttryTabs" href="javascript:void(0)" class="largeh2 d-flex align-items-center green">Europe <vue-feather size="28px" class="ms-1" type="chevron-down"></vue-feather></a>
                                        <ul class="dropdown_menu_animated" :class="counttryTabs?'show':''">
                                            <li><a href="javascript:void(0)">Europe</a></li>
                                            <li><a href="javascript:void(0)">America</a></li>
                                            <li><a href="javascript:void(0)">Asia</a></li>
                                            <li><a href="javascript:void(0)">Africa</a></li>
                                            <li><a href="javascript:void(0)">Australia</a></li>
                                            <li><a href="javascript:void(0)">G20</a></li>
                                            <li>
                                                <a href="javascript:void(0)">Others</a>
                                                <form class="showSearch">
                                                    <div class="form-group position-relative inputIcon">
                                                        <input type="text" class="form-control" placeholder="Search Others" name="" /> 
                                                        <a class="searchButton" href="#"><vue-feather type="search"></vue-feather> </a>
                                                    </div>
                                                </form>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center">
                                    <p class="mb-0 medium line-1"><span class="green">+5%</span> (2021)</p>
                                </div>
                            </div>
                            <div class="rightSearch back">
                                <router-link to="/gdp-india-forecase" class="link d-flex align-items-center"><vue-feather type="chevron-left" size="18px" class="me-1"></vue-feather> Back to Previous Page</router-link>
                            </div>
                        </div>
                        <div class="d-flex align-items-center justify-content-between border-bottom trade rtBtn">
                            <ul class="toplinks d-flex align-items-center mb-0">
                                <li class="ps-0"><a @click.prevent="actualTab = 'Actual'" :class="(actualTab == 'Actual')?'active':''" href="javascript:void(0)">Actual</a></li>
                                <li><a @click.prevent="actualTab = 'Perfomance'" :class="(actualTab == 'Perfomance')?'active':''" href="javascript:void(0)"> Perfomance</a></li>
                            </ul>
                            <div class="d-flex align-items-center dWatch">
                                <router-link to="/economic-calendar" class="hideMobile iconcolor border-btn"> Economic Calendar </router-link>
                                <router-link to="/economic-overview" class="mx-md-3 me-2 border-btn darkBG"> Economy Overview</router-link>
                                <a href="javascript:void(0)" class="trash"> <img width="19" src="assets/images/comparechartIcon.svg" alt="Icon" title="Line comparison inner page"> </a>
                            </div>
                        </div>
                        <div class="chartVie mt-4 mb-md-5">
                            <img class="w-100" src="assets/images/chart/comgpChart.svg" alt="Chart" title="GDP Growth Rate ">
                        </div>
                        <div class="relatedNews">
                            <h5 class="mb-4 medium">Related  News</h5>
                            <div class="col-12">
                                <div class="row justify-content-between">
                                    <div class="col-12 col-lg-5 mb-4">
                                        <div class="card-body p-0">
                                            <h6><a href="javascript:void(0)">US 10Y yields point to some consolidation around 1.28%...</a></h6>
                                            <p class="d-flex align-items-center f-12 lighttext mb-1"><vue-feather size="14px" class="me-1" type="clock"></vue-feather> 7 hours ago</p>
                                            <p class="mb-2">The US dollar is trading in a narrow range ahead of the event, typical of such days.</p>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-5 mb-4">
                                        <div class="card-body p-0">
                                            <h6><a href="javascript:void(0)">US 10Y yields point to some consolidation around 1.28%...</a></h6>
                                            <p class="d-flex align-items-center f-12 lighttext mb-1"><vue-feather size="14px" class="me-1" type="clock"></vue-feather> 7 hours ago</p>
                                            <p class="mb-2">The US dollar is trading in a narrow range ahead of the event, typical of such days.</p>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-5 mb-4">
                                        <div class="card-body p-0">
                                            <h6><a href="javascript:void(0)">US 10Y yields point to some consolidation around 1.28%...</a></h6>
                                            <p class="d-flex align-items-center f-12 lighttext mb-1"><vue-feather size="14px" class="me-1" type="clock"></vue-feather> 7 hours ago</p>
                                            <p class="mb-2">The US dollar is trading in a narrow range ahead of the event, typical of such days.</p>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-5 mb-4">
                                        <div class="card-body p-0">
                                            <h6><a href="javascript:void(0)">US 10Y yields point to some consolidation around 1.28%...</a></h6>
                                            <p class="d-flex align-items-center f-12 lighttext mb-1"><vue-feather size="14px" class="me-1" type="clock"></vue-feather> 7 hours ago</p>
                                            <p class="mb-2">The US dollar is trading in a narrow range ahead of the event, typical of such days.</p>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-5 mb-4">
                                        <div class="card-body p-0">
                                            <h6><a href="javascript:void(0)">US 10Y yields point to some consolidation around 1.28%...</a></h6>
                                            <p class="d-flex align-items-center f-12 lighttext mb-1"><vue-feather size="14px" class="me-1" type="clock"></vue-feather> 7 hours ago</p>
                                            <p class="mb-2">The US dollar is trading in a narrow range ahead of the event, typical of such days.</p>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-5 mb-4">
                                        <div class="card-body p-0">
                                            <h6><a href="javascript:void(0)">US 10Y yields point to some consolidation around 1.28%...</a></h6>
                                            <p class="d-flex align-items-center f-12 lighttext mb-1"><vue-feather size="14px" class="me-1" type="clock"></vue-feather> 7 hours ago</p>
                                            <p class="mb-2">The US dollar is trading in a narrow range ahead of the event, typical of such days.</p>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-5 mb-4">
                                        <div class="card-body p-0">
                                            <h6><a href="javascript:void(0)">US 10Y yields point to some consolidation around 1.28%...</a></h6>
                                            <p class="d-flex align-items-center f-12 lighttext mb-1"><vue-feather size="14px" class="me-1" type="clock"></vue-feather> 7 hours ago</p>
                                            <p class="mb-2">The US dollar is trading in a narrow range ahead of the event, typical of such days.</p>
                                        </div>
                                    </div>
                                    <div class="col-12 col-lg-5 mb-4">
                                        <div class="card-body p-0">
                                            <h6><a href="javascript:void(0)">US 10Y yields point to some consolidation around 1.28%...</a></h6>
                                            <p class="d-flex align-items-center f-12 lighttext mb-1"><vue-feather size="14px" class="me-1" type="clock"></vue-feather> 7 hours ago</p>
                                            <p class="mb-2">The US dollar is trading in a narrow range ahead of the event, typical of such days.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> 
                    <div class="col-12 col-xl-4 maxColumn space ps-lg-5">
                        <div class="halfBG space secondaryBG h-100">
                            <div class="position-sticky top80">
                                <div class="border-bottom trade pb-3 mb-4">
                                    <h2>Other Indicators</h2>
                                </div>
                                <div class="accordianList">
                                    <h6 @click.prevent="indicatorsTabs = '1'" :class="(indicatorsTabs == '1')?'active':''" class="pointer d-flex align-items-center justify-content-between"> 
                                        <span class="rounded-circle"><img src="assets/images/global.svg" alt="global" title="GDP"></span>
                                        <span class="d-flex align-items-center justify-content-between clickView"> GDP <vue-feather size="18px" type="chevron-down"></vue-feather></span>
                                    </h6>
                                    <ul class="openAccordian" :class="(indicatorsTabs == '1')?'show':''">
                                        <li><a class="active" href="javascript:void(0)">GDP</a></li>
                                        <li><a href="javascript:void(0)">GNP</a></li>
                                        <li><a href="javascript:void(0)">GDP Constant Rate </a></li>
                                        <li><a href="javascript:void(0)">GDP Fixed Capital Formation</a></li>
                                    </ul>
                                </div>
                                <div class="accordianList">
                                    <h6 @click.prevent="indicatorsTabs = '2'" :class="(indicatorsTabs == '2')?'active':''" class="pointer d-flex align-items-center justify-content-between"> 
                                        <span class="rounded-circle"><img src="assets/images/company.svg" alt="global" title="GDP"></span> 
                                        <span class="d-flex align-items-center justify-content-between clickView"> Markets <vue-feather size="18px" type="chevron-down"></vue-feather></span>
                                    </h6>
                                    <ul class="openAccordian" :class="(indicatorsTabs == '2')?'show':''">
                                        <li><a class="active" href="javascript:void(0)">GDP</a></li>
                                        <li><a href="javascript:void(0)">GNP</a></li>
                                        <li><a href="javascript:void(0)">GDP Constant Rate </a></li>
                                        <li><a href="javascript:void(0)">GDP Fixed Capital Formation</a></li>
                                    </ul>
                                </div>
                                <div class="accordianList">
                                    <h6 @click.prevent="indicatorsTabs = '3'" :class="(indicatorsTabs == '3')?'active':''" class="pointer d-flex align-items-center justify-content-between"> 
                                        <span class="rounded-circle"><img src="assets/images/labour.svg" alt="global" title="GDP"></span> 
                                        <span class="d-flex align-items-center justify-content-between clickView">Labour <vue-feather size="18px" type="chevron-down"></vue-feather></span>
                                    </h6>
                                    <ul class="openAccordian" :class="(indicatorsTabs == '3')?'show':''">
                                        <li><a class="active" href="javascript:void(0)">GDP</a></li>
                                        <li><a href="javascript:void(0)">GNP</a></li>
                                        <li><a href="javascript:void(0)">GDP Constant Rate </a></li>
                                        <li><a href="javascript:void(0)">GDP Fixed Capital Formation</a></li>
                                    </ul>
                                </div>
                                <div class="accordianList">
                                    <h6 @click.prevent="indicatorsTabs = '4'" :class="(indicatorsTabs == '4')?'active':''" class="pointer d-flex align-items-center justify-content-between"> 
                                        <span class="rounded-circle"><img src="assets/images/price.svg" alt="global" title="GDP"></span> 
                                        <span class="d-flex align-items-center justify-content-between clickView">Prices <vue-feather size="18px" type="chevron-down"></vue-feather></span>
                                    </h6>
                                    <ul class="openAccordian" :class="(indicatorsTabs == '4')?'show':''">
                                        <li><a class="active" href="javascript:void(0)">GDP</a></li>
                                        <li><a href="javascript:void(0)">GNP</a></li>
                                        <li><a href="javascript:void(0)">GDP Constant Rate </a></li>
                                        <li><a href="javascript:void(0)">GDP Fixed Capital Formation</a></li>
                                    </ul>
                                </div>
                                <div class="accordianList">
                                    <h6 @click.prevent="indicatorsTabs = '5'" :class="(indicatorsTabs == '5')?'active':''" class="pointer d-flex align-items-center justify-content-between"> 
                                        <span class="rounded-circle"><img src="assets/images/health.svg" alt="global" title="GDP"></span> 
                                        <span class="d-flex align-items-center justify-content-between clickView">Health <vue-feather size="18px" type="chevron-down"></vue-feather></span>
                                    </h6>
                                    <ul class="openAccordian" :class="(indicatorsTabs == '5')?'show':''">
                                        <li><a class="active" href="javascript:void(0)">GDP</a></li>
                                        <li><a href="javascript:void(0)">GNP</a></li>
                                        <li><a href="javascript:void(0)">GDP Constant Rate </a></li>
                                        <li><a href="javascript:void(0)">GDP Fixed Capital Formation</a></li>
                                    </ul>
                                </div>
                                <div class="accordianList">
                                    <h6 @click.prevent="indicatorsTabs = '6'" :class="(indicatorsTabs == '6')?'active':''" class="pointer d-flex align-items-center justify-content-between"> 
                                        <span class="rounded-circle"><img src="assets/images/indicator.svg" alt="global" title="GDP"></span>
                                        <span class="d-flex align-items-center justify-content-between clickView"> Trade<vue-feather size="18px" type="chevron-down"></vue-feather> </span>
                                    </h6>
                                    <ul class="openAccordian" :class="(indicatorsTabs == '6')?'show':''">
                                        <li><a class="active" href="javascript:void(0)">GDP</a></li>
                                        <li><a href="javascript:void(0)">GNP</a></li>
                                        <li><a href="javascript:void(0)">GDP Constant Rate </a></li>
                                        <li><a href="javascript:void(0)">GDP Fixed Capital Formation</a></li>
                                    </ul>
                                </div>
                                <div class="accordianList">
                                    <h6 @click.prevent="indicatorsTabs = '7'" :class="(indicatorsTabs == '7')?'active':''" class="pointer d-flex align-items-center justify-content-between"> 
                                        <span class="rounded-circle"><img src="assets/images/business.svg" alt="global" title="GDP"></span>
                                        <span class="d-flex align-items-center justify-content-between clickView"> Business <vue-feather size="18px" type="chevron-down"></vue-feather></span>
                                    </h6>
                                    <ul class="openAccordian" :class="(indicatorsTabs == '7')?'show':''">
                                        <li><a class="active" href="javascript:void(0)">GDP</a></li>
                                        <li><a href="javascript:void(0)">GNP</a></li>
                                        <li><a href="javascript:void(0)">GDP Constant Rate </a></li>
                                        <li><a href="javascript:void(0)">GDP Fixed Capital Formation</a></li>
                                    </ul>
                                </div>
                                <div class="accordianList">
                                    <h6 @click.prevent="indicatorsTabs = '8'" :class="(indicatorsTabs == '8')?'active':''" class="pointer d-flex align-items-center justify-content-between"> 
                                        <span class="rounded-circle"><img src="assets/images/government.svg" alt="global" title="GDP"></span> 
                                        <span class="d-flex align-items-center justify-content-between clickView">Government <vue-feather size="18px" type="chevron-down"></vue-feather></span>
                                    </h6>
                                    <ul class="openAccordian" :class="(indicatorsTabs == '8')?'show':''">
                                        <li><a class="active" href="javascript:void(0)">GDP</a></li>
                                        <li><a href="javascript:void(0)">GNP</a></li>
                                        <li><a href="javascript:void(0)">GDP Constant Rate </a></li>
                                        <li><a href="javascript:void(0)">GDP Fixed Capital Formation</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        
    </div>
</template>

<script>
import bannerslider from "../../components/shared/banner_slider";
export default {
    data() {
        return {
            countryTabs:'Europe',
            indicatorsTabs:'1',
            actualTab:'Actual',
            counttryTabs:false,
        }
    },
    components: {
        bannerslider,
    },     
}
</script>
